import dirs from "@/contentful/content-model/dirs";
import type { Dirs } from "@/contentful/content-model/types";
import type { Asset, KeyVisual, MediaPlayer, Meta } from "@/types/contentful-api";
import { replaceHandlebars } from "@/utils/markdown";

interface MetaTagBuilderProps {
	defaultMeta: Meta;
	pageMeta?: Meta;
	titleOverride?: string;
	descriptionOverride?: string;
	imageOverride?: Asset;
	replacements?: Record<string, string>;
}

export const OG_IMAGE_WIDTH = "1280";
export const OG_IMAGE_HEIGHT = "720";
export const OG_PERSON_IMAGE_SIZE = "1000";
export const OG_SMALL_IMAGE_SIZE = "400";

export const CONTENTFUL_IMAGE_PARAMS = `?w=${OG_IMAGE_WIDTH}&h=${OG_IMAGE_HEIGHT}&f=center&fit=fill&fm=jpg&q=90`;
export const CONTENTFUL_IMAGE_PARAMS_FACES = `?w=${OG_PERSON_IMAGE_SIZE}&h=${OG_PERSON_IMAGE_SIZE}&f=faces&fit=fill&fm=jpg&q=90`;
export const CONTENTFUL_IMAGE_PARAMS_SMALL = `?w=${OG_SMALL_IMAGE_SIZE}&h=${OG_SMALL_IMAGE_SIZE}&f=center&fit=fill&fm=jpg&q=90`;
export const CONTENTFUL_IMAGE_PARAMS_SMALL_FACES = `?w=${OG_SMALL_IMAGE_SIZE}&h=${OG_SMALL_IMAGE_SIZE}&f=faces&fit=fill&fm=jpg&q=90`;

export const getOGImageContents = (url: string) => {
	const imageUrl = url.split("?w").splice(0, 1).join("");
	const isPerson = url.includes("f=faces");
	const params = isPerson ? CONTENTFUL_IMAGE_PARAMS_SMALL_FACES : CONTENTFUL_IMAGE_PARAMS_SMALL;

	return {
		largeWidth: isPerson ? OG_PERSON_IMAGE_SIZE : OG_IMAGE_WIDTH,
		largeHeight: isPerson ? OG_PERSON_IMAGE_SIZE : OG_IMAGE_HEIGHT,
		smallUrl: [imageUrl, params].join(""),
		smallSize: OG_SMALL_IMAGE_SIZE,
	};
};

export const getSEOAsset = (data: KeyVisual | MediaPlayer | Asset): Asset | undefined => {
	switch (data?.__typename) {
		case "Asset":
			return {
				...data,
				url: `${data.url}${CONTENTFUL_IMAGE_PARAMS}`,
			};
		case "KeyVisual":
			return {
				...data.featuredImage,
				url: `${data.featuredImage.url}${CONTENTFUL_IMAGE_PARAMS}`,
			};
		case "MediaPlayer":
			return {
				...data.poster,
				url: `${data.poster.url}${CONTENTFUL_IMAGE_PARAMS}`,
			};
		default:
			return;
	}
};

export const buildMetaTags = ({
	defaultMeta,
	pageMeta,
	titleOverride,
	descriptionOverride,
	imageOverride,
	replacements,
}: MetaTagBuilderProps) => {
	let meta = defaultMeta;

	// Overwrite meta titles with specific titleOverride, if passed in
	if (titleOverride) {
		meta.metaTitle = titleOverride;
		meta.openGraphTitle = titleOverride;
		meta.schemaTitle = titleOverride;
		meta.twitterTitle = titleOverride;
	}

	// Overwrite meta descriptions with specific descriptionOverride, if passed in
	if (descriptionOverride) {
		meta.metaDescription = descriptionOverride;
		meta.openGraphDescription = descriptionOverride;
		meta.schemaDescription = descriptionOverride;
		meta.twitterDescription = descriptionOverride;
	}

	// Overwrite meta images with specific imageOverride, if passed in
	if (imageOverride?.__typename === "Asset") {
		meta.schemaImage = imageOverride;
		meta.twitterImage = imageOverride;
		meta.openGraphImage = imageOverride;
	}

	// If pageMeta, merge default and page meta, overwriting default values with 'truthy' values from page meta
	if (pageMeta?.__typename === "Meta") {
		meta = Object.keys(defaultMeta).reduce((items, key) => {
			const customMetaValue = pageMeta[key];
			return {
				...items,
				[key]: Boolean(customMetaValue) ? customMetaValue : defaultMeta[key],
			};
		}, {} as Meta);
	}

	// Replace placeholders in all first level meta string values with replacement values, if passed in
	if (replacements) {
		meta = Object.keys(defaultMeta).reduce((items, key) => {
			return {
				...items,
				[key]:
					typeof meta[key] === "string"
						? replaceHandlebars(meta[key], replacements)
						: meta[key],
			};
		}, {} as Meta);
	}

	return meta;
};

export const localizeCanonical = (
	robots: string[],
	page: Dirs,
	locale: string,
	path: string[] = []
): string => {
	if ((robots ?? []).some(tag => tag === "noindex" || tag === "nofollow")) {
		return "";
	}

	const localizedPage = dirs[page]?.dir?.[locale] ?? "";

	const localizedPageSeparator = localizedPage.length > 0 ? "/" : "";
	const pathSeparator = path.length > 0 ? "/" : "";

	return `https://www.evernest.com/${locale}/${localizedPage}${localizedPageSeparator}${path.join(
		"/"
	)}${pathSeparator}`;
};
