import type { ContentTypeProps as ContentfulContentTypeProps } from "contentful-management";

export enum FieldId {
	aspectRatio = "aspectRatio",
	author = "author",
	backgroundPrimary = "backgroundPrimary",
	backgroundSecondary = "backgroundSecondary",
	breadcrumb = "breadcrumb",
	centered = "centered",
	checkboxes = "checkboxes",
	city = "city",
	config = "config",
	contentType = "contentType",
	country = "country",
	contactPerson = "contactPerson",
	data = "data",
	description = "description",
	dir = "dir",
	displayType = "displayType",
	navigationLinkType = "navigationLinkType",
	displayVideoOnMobile = "showMobileVideo",
	district = "district",
	cityDistrict = "cityDistrict",
	districtLink = "districtLink",
	email = "email",
	entryLink = "entryLink",
	entryListFilter = "entryListFilter",
	externalUrl = "externalUrl",
	externalCTA = "externalCTA",
	exportedPropertyData = "exportedPropertyData",
	featuredImage = "featuredImage",
	featuredImageMobile = "featuredImageMobile",
	featuredVideo = "featuredVideo",
	file = "file",
	filter = "filter",
	flip = "flip",
	formType = "formType",
	gender = "gender",
	geocode = "geocode",
	geometry = "geometry",
	headline = "headline",
	headlineCentered = "headlineCentered",
	headquarter = "headquarter",
	icon = "icon",
	internalEntryLink = "internalEntryLink",
	id = "id",
	iframeUrl = "iframeUrl",
	images = "images",
	imageSlideshow = "imageSlideshow",
	isAgentApplicationForm = "isAgentApplicationForm",
	items = "items",
	jobId = "jobId",
	jobFilters = "jobFilters",
	jobLevel = "jobLevel",
	jobLocation = "jobLocation",
	jobLocations = "jobLocations",
	jobDepartment = "jobDepartment",
	location = "location",
	locations = "locations",
	limit = "limit",
	label = "label",
	layoutType = "layoutType",
	listedIn = "listedIn",
	listedInRegion = "listedInRegion",
	marketingLinkURL = "marketingLinkURL",
	meta = "meta",
	mediaAssetImage = "mediaAssetImage",
	name = "name",
	navigation = "navigation",
	navigationItem = "navigationItem",
	navigationLocation = "navigationLocation",
	polygon = "polygon",
	picture = "picture",
	pictureConfig = "pictureConfig",
	region = "region",
	rows = "rows",
	showAgentProperties = "showAgentProperties",
	shouldShowSnackbar = "shouldShowSnackbar",
	slideshowConfig = "slideshowConfig",
	structuredData = "structuredData",
	slots = "slots",
	slug = "slug",
	state = "state",
	subtitle = "subtitle",
	target = "target",
	team = "team",
	termsType = "termsType",
	title = "title",
	videoUrl = "videoUrl",
	video = "video",
	videoButtonText = "videoButtonText",
	videoPreview = "videoPreview",
	videoHd = "videoHd",
	videoSd = "videoSd",
	wave = "wave",
	widgetTitle = "widgetTitle",
	widgetType = "widgetType",
	zoomLevel = "zoomLevel",
}

export enum ContentType {
	agentPlatformPage = "agentPlatformPage",
	boxes = "boxes",
	blogPost = "blog-post",
	businessInfo = "business-info",
	city = "city",
	contactForm = "contactForm",
	contactPerson = "contactPerson",
	country = "country",
	district = "district",
	entryList = "entry-list",
	exportedPropertyData = "exported-property-data",
	featureFlags = "featureFlags",
	guideCategory = "guideCategory",
	headquarter = "headquarter",
	iconText = "icon-text",
	iframe = "iframe",
	images = "images",
	imageSlideshow = "image-slideshow",
	imageText = "image-text",
	infoText = "info-text",
	jobApplicationForm = "jobApplicationForm",
	jobListings = "jobListings",
	jobLocation = "jobLocation",
	keyVisual = "key-visual",
	list = "list",
	mapLocationLinks = "mapLocationLinks",
	markdown = "markdown",
	marketingLink = "marketing-link",
	mediaPlayer = "mediaPlayer",
	meta = "meta",
	multiLink = "multiLink",
	multiLinkCategory = "multiLinkCategory",
	navigation = "navigation",
	navigationItem = "navigationItem",
	newsletterSubscription = "newsletterSubscription",
	quote = "quote",
	page = "page",
	person = "person",
	personList = "personList",
	picture = "picture",
	pictureText = "picture-text",
	property = "property",
	propertyList = "propertyList",
	propertyTableRow = "propertyTableRow",
	region = "region",
	singleLink = "single-link",
	slideshow = "slideshow",
	subPage = "sub-page",
	table = "table",
	testimonials = "testimonials",
	team = "team",
	termsConditionsPage = "termsConditionsPage",
	text = "text",
	video = "video",
	widget = "widget",
	valuationResult = "valuationResult",
}

export type ROOT = "ROOT";

export type ContentTypes = {
	[value in ContentType]: ContentfulContentTypeProps;
};

// @todo: check this type closely, looks like Next.js should cover i18n completely so no need to
//   invent the wheel
export enum Locale {
	de = "de",
	es = "es",
}

export enum Country {
	de = "Germany",
	es = "Spain",
}

export type LocalizedString = {
	[key in Locale]: string;
};

export interface Dir {
	breadcrumb?: LocalizedString;
	dir?: LocalizedString;
	href: string;
}

export enum Dirs {
	blog = "blog",
	careers = "careers",
	pages = "pages",
	about = "about",
	applyAsAgent = "applyAsAgent",
	ourAgents = "ourAgents",
	buy = "buy",
	faq = "faq",
	guide = "guide",
	imprint = "imprint",
	living = "living",
	press = "press",
	privacyPolicy = "privacyPolicy",
	property = "property",
	realEstateValuation = "realEstateValuation",
	region = "region",
	rent = "rent",
	ROOT = "ROOT",
	search = "search",
	sell = "sell",
	valuation = "valuation",
	valuationResult = "valuationResult",
	documentTemplates = "documentTemplates",
	cookiePolicy = "cookiePolicy",
	notFound = "404",
	whistleblower = "whistleblower",
}

export interface Action {
	breadcrumb?: LocalizedString;
	action?: LocalizedString;
	href: string;
}

export enum Actions {
	buy = "buy",
	sell = "sell",
}

export enum DisplayType {
	collage = "collage",
	slider = "slider",
	layout1 = "layout1",
	layout2 = "layout2",
	layout3 = "layout3",
	layout4 = "layout4",
	layout6 = "layout6",
	layout7 = "layout7",
	logos = "Logos",
	iconText = "Icon Text",
	infoSection = "Info Section",
	borderlessIconText = "Borderless Icon Text",
}

export enum EntryListContentTypes {
	blogPost = "blog-post",
	person = "person",
	subPage = "sub-page",
}

export enum LayoutType {
	medium = "medium",
	full = "full",
}

export enum FormType {
	APPLICATION = "APPLICATION",
	BUYER = "BUYER",
	SELLER = "SELLER",
	AGENT = "AGENT",
	SELECTED = "SELECTED",
	LANDLORD = "LANDLORD",
	CONTACT_NO_SNACKBAR = "CONTACT_NO_SNACKBAR",
	PARTNER = "PARTNER",
}

export enum NavigationLocation {
	header = "header",
	footer = "footer",
}

export enum TermsType {
	webExpose = "web-expose",
	rentalProcess = "rental-process",
}
